import "./styles.scss";
import Card from "app/components/basic/Card";
import React from "react";
import translations from "app/assets/files/key-value-translations/homepage.json";
import { IProps } from "app/pages";

const EventListingPlaceholderCard = (props: IProps): JSX.Element => {
	return (
		<Card isStatic className="event-listing-placeholder-card-container">
			<img
				className="event-listing-placeholder-card-container__image-workshops"
				src="/images/upcoming-workshops.png"
				alt={translations[props.language].homepageUpcomingWorkshopsImage.data}
			/>
			<p>{translations[props.language].homepageUpcomingWorkshopsDescription.data}</p>
		</Card>
	);
};

export default EventListingPlaceholderCard;
