import { ReactNode } from "react";
import { Grid } from "semantic-ui-react";

interface IProps {
	className: string;
	children: ReactNode;
}
const StandardSection = (props: IProps): JSX.Element => (
	<Grid.Row>
		<Grid.Column mobile={12} tablet={12} computer={8} className={`no-padding-top-bottom ${props.className}`}>
			{props.children}
		</Grid.Column>
	</Grid.Row>
);

export default StandardSection;
