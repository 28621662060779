import translations from "app/assets/files/key-value-translations/homepage.json";
import edcTranslations from "app/assets/files/key-value-translations/when-death-happens-overview-pg.json";
import { CardWithImage } from "app/components/basic/Card";
import Segment from "app/components/page/Segment";
import { IProps } from "app/pages";
import { Grid } from "semantic-ui-react";
import { baseEndOfLifeUrl } from "../Guide/__common__/Topic";

export const Guides = (props: IProps): JSX.Element => {
	return (
		<Segment className="landing-page-bottom-margin">
			<Grid>
				<Grid.Row columns={12}>
					<Grid.Column mobile={12} tablet={6} computer={6} className="index__card">
						<CardWithImage
							id="end-of-life-planning"
							header={translations[props.language].homepageEOLPlanning.data}
							description={translations[props.language].homepageEOLPlanningDescription.data}
							image="/images/features-planning-ahead.png"
							imageAltText={translations[props.language].homepageEOLPlanningImage.data}
							href={baseEndOfLifeUrl}
							height={135}
							width={230}
						/>
					</Grid.Column>
					<Grid.Column mobile={12} tablet={6} computer={6} className="index__card">
						<CardWithImage
							id="when-death-happens"
							header={translations[props.language].homepageWhenDeathHappens.data}
							description={edcTranslations[props.language].homepageWhenDeathHappensDescription.data}
							image="/images/when-death-happens.png"
							imageAltText={translations[props.language].homepageWhenDeathHappensImage.data}
							href="/when-death-happens"
							height={135}
							width={187}
						/>
					</Grid.Column>
				</Grid.Row>
			</Grid>
		</Segment>
	);
};

export default Guides;
