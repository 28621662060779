import { GenericNetworkErrorMsg } from "app/constants/networkErrors";
import { useAlert } from "app/hooks/useAlert";
import { AxiosResponse } from "axios";
import _ from "lodash";
import { useCallback, useEffect, useRef } from "react";
import "./DownloadButton.scss";
import { DownloadIcon } from "@lifesg/react-icons";

export interface ICallback {
	callback: () => Promise<AxiosResponse>;
}
interface IProps extends ICallback {
	fileName: string;
	autoDownload?: boolean;
	showLoading?: boolean;
	debounceTime?: number;
}

const DownloadButton = ({ showLoading, callback, fileName, debounceTime, autoDownload }: IProps): JSX.Element => {
	const aRef = useRef<HTMLAnchorElement>(null);
	const loadingIcon = useRef<HTMLDivElement>(null);
	const downloadIcon = useRef<HTMLDivElement>(null);
	const button = useRef<HTMLDivElement>(null);
	const { toast } = useAlert();

	const setLoading = useCallback(
		(loading: boolean) => {
			if (showLoading && button.current && loadingIcon.current && downloadIcon.current) {
				button.current.style.pointerEvents = loading ? "none" : "auto";
				loadingIcon.current.className = "icon-container " + (loading ? "" : "hidden");
				downloadIcon.current.className = "icon-container " + (loading ? "hidden" : "");
			}
		},
		[showLoading],
	);

	const downloadCallback = useCallback(async (): Promise<void> => {
		setLoading(true);
		try {
			const response = await callback();
			if (aRef && aRef.current) {
				aRef.current.href = window.URL.createObjectURL(
					new Blob([response.data], { type: response.headers["content-type"] }),
				);
				aRef.current.download = fileName;
				aRef.current.click();
			}
		} catch (err) {
			toast("failure", GenericNetworkErrorMsg);
		} finally {
			setLoading(false);
		}
	}, [callback, fileName, setLoading, toast]);

	const debounceCallback = _.debounce(downloadCallback, debounceTime ?? 2000, {
		leading: true,
		trailing: false,
	});

	useEffect(() => {
		const sessionStorageKey = `auto-downloaded-${fileName}`;
		const hasAutoDownloaded = window.sessionStorage.getItem(sessionStorageKey) === "true";

		if (autoDownload && !hasAutoDownloaded) {
			void downloadCallback();
			window.sessionStorage.setItem(sessionStorageKey, "true");
		}
	}, [autoDownload, downloadCallback, fileName]);

	return (
		<>
			<div className="mt16" />
			<div ref={button} className="combined-tool-next-steps__download-button" onClick={debounceCallback}>
				<div className="combined-tool-next-steps__download-button__display-text">
					<small>{fileName}</small>
				</div>
				<div ref={loadingIcon} className="icon-container hidden">
					<div className="loader-spin">
						<svg>
							<circle className="loader-spin_preLoader" cx="10" cy="10" r="8" />
						</svg>
					</div>
				</div>
				<div ref={downloadIcon} className="icon-container">
					<DownloadIcon className="combined-tool-next-steps__download-button__icon" />
				</div>
			</div>
			<a ref={aRef} download={true} />
			<div className="mt16" />
		</>
	);
};

export default DownloadButton;
