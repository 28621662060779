import relatedGuidesMetadata from "app/assets/files/related-guides.json";
import topicTypesMetadata from "app/assets/files/translations/navbar.json";
import Breadcrumb from "app/components/basic/Breadcrumb";
import BasicLink from "app/components/basic/Link";
import PageHeader from "app/components/composites/PageHeader";
import RelatedLinks from "app/components/composites/RelatedLinks/RelatedLinks";
import StandardSection from "app/components/composites/Section/Section";
import Segment from "app/components/page/Segment";
import {
	EstateSettlementsGuidesTitles,
	FuneralMattersGuidesTitles,
	getGuideHref,
	getMultiStepGuideHref,
	IAllGuideListType,
	IAllMultipageGuideListType,
	ITopic,
	MultipageGuideList,
	PalliativeCareGuidesTitles,
	PlanningAheadGuidesList,
	ReportingADeathGuidesList,
	TopicListType,
	TopicType,
} from "app/components/templates/Guide/__common__/Topic";
import ExternalLinkIcon from "app/components/widgets/ExternalLinkIcon";
import { ILinkItem } from "app/utils/interfaces";
import { useGlobalLanguageState } from "app/utils/internationalization/GlobalLanguageProvider";
import { TranslationPageRenderer, ITranslatedObject } from "app/utils/internationalization/TranslationPageRenderer";
import { lowercaseAndHyphen } from "app/utils/strings";
import parse from "html-react-parser";
import { Grid } from "semantic-ui-react";
import "./SinglePageGuide.scss";
import { ReactNode } from "react";

interface IProps {
	// currentTopic is for setting the breadcrumbs, and to set the related guides
	currentTopic: ITopic;
	relatedGuides:
		| Partial<typeof PlanningAheadGuidesList>
		| typeof PalliativeCareGuidesTitles
		| typeof ReportingADeathGuidesList
		| typeof FuneralMattersGuidesTitles
		| typeof EstateSettlementsGuidesTitles;
	currentGuideTitle: IAllGuideListType;
	currentTitle?: string;
	currentSectionTitle?: string;
	internalMenu?: JSX.Element;
	translatedObjects?: ITranslatedObject[];
	children?: ReactNode;
}

interface IGuideImage {
	src: any;
	alt?: string;
	style?: React.CSSProperties;
}

const SinglePageGuide = (props: IProps): JSX.Element => {
	const { currentTopic, currentGuideTitle, currentSectionTitle, currentTitle } = props;
	const [, selectedLanguage] = useGlobalLanguageState();

	const getTranslatedCopy = (sheetName: string, copyForTranslation: string): string => {
		const translatedCopy = relatedGuidesMetadata[sheetName][selectedLanguage].find(
			({ key }: { key: string }) => key === lowercaseAndHyphen(copyForTranslation),
		);
		return translatedCopy ? translatedCopy.data : copyForTranslation;
	};

	const getTopicListType = (): TopicListType => {
		switch (props.relatedGuides) {
			case PlanningAheadGuidesList:
				return TopicListType.planningAhead;
			case PalliativeCareGuidesTitles:
				return TopicListType.palliativeCare;
			case ReportingADeathGuidesList:
				return TopicListType.reportingADeath;
			case FuneralMattersGuidesTitles:
				return TopicListType.funeralMatters;
			case EstateSettlementsGuidesTitles:
				return TopicListType.estateSettlements;
			default:
				return TopicListType.planningAhead;
		}
	};

	const title = currentTitle || getTranslatedCopy(getTopicListType(), currentGuideTitle);

	const GuideHeader = (): JSX.Element => {
		const getTranslatedTopicType = (topicType: string): string => {
			const found = topicTypesMetadata["en"].find(
				({ translation }: { translation: string }) => translation === topicType,
			);
			return found
				? topicTypesMetadata[selectedLanguage].find(({ key }: { key: string }) => key === found.key)!
						.translation
				: topicType;
		};

		return (
			<>
				<Grid.Row>
					<Grid.Column mobile={12} tablet={12} computer={12} className={"mt40 no-padding-vertical"}>
						<Breadcrumb
							pages={[
								{
									name: getTranslatedTopicType(currentTopic.type),
									path: `/${lowercaseAndHyphen(currentTopic.type)}`,
								},
							]}
							currentPageName={title}
						/>
					</Grid.Column>
				</Grid.Row>
				<StandardSection className="no-margin">
					<h1>{title}</h1>
				</StandardSection>
			</>
		);
	};

	const RelatedGuides = (): JSX.Element => {
		const otherRelatedGuidesTitle = Object.values(props.relatedGuides).filter((value: string) => {
			return value !== currentGuideTitle;
		});

		const linkItems: ILinkItem[] = otherRelatedGuidesTitle.map((guidesTitle: IAllGuideListType): ILinkItem => {
			const isSinglePageGuide = MultipageGuideList[guidesTitle] === undefined;
			return {
				name: getTranslatedCopy(getTopicListType(), guidesTitle),
				path: isSinglePageGuide
					? getGuideHref(currentTopic.type, guidesTitle)
					: getMultiStepGuideHref(currentTopic.type, guidesTitle as IAllMultipageGuideListType, 0),
			};
		});

		const translatedHeaderText = getTranslatedCopy("miscellaneous", "Related guides");
		return (
			<Grid.Column mobile={12} tablet={12} computer={3} className="no-padding-top-bottom" floated="right">
				<div className="single-page-guide__related-guides ">
					<RelatedLinks headerText={translatedHeaderText} linkItems={linkItems} />
				</div>
			</Grid.Column>
		);
	};

	return (
		<div className="single-page-guide">
			<PageHeader
				title={`${currentSectionTitle || getTranslatedCopy(getTopicListType(), currentGuideTitle)} - My Legacy`}
			/>
			<Segment paddingBottom={104}>
				<Grid>
					<GuideHeader />
					<Grid.Row>
						{props.internalMenu && (
							<>
								<Grid.Column
									mobile={12}
									tablet={12}
									computer={8}
									className="single-page-guide__internal-menu no-padding-top-bottom no-margin floated left"
								>
									{props.internalMenu}
								</Grid.Column>
							</>
						)}
						<Grid.Column
							mobile={12}
							tablet={12}
							computer={8}
							className={`single-page-guide__children no-padding-top-bottom no-margin`}
						>
							<main>
								{props.translatedObjects && (
									<TranslationPageRenderer translatedObjects={props.translatedObjects} />
								)}
								{props.children}
							</main>
						</Grid.Column>
						<RelatedGuides />
					</Grid.Row>
				</Grid>
			</Segment>
		</div>
	);
};

export default SinglePageGuide;

// =============================================================================
// Helper components for guides
// =============================================================================
export const GuidesSinglePageInternalLink = (
	linkName: string,
	topic: TopicType,
	guide: IAllGuideListType,
	bookmarkId = "",
): JSX.Element => {
	return (
		<BasicLink
			href={`${getGuideHref(topic, guide)}${bookmarkId ? "#" : ""}${bookmarkId}`}
			name={linkName}
			className="guides-internal-link__button"
			id={`guides-internal-link__${lowercaseAndHyphen(linkName)}`}
		/>
	);
};

export const GuidesMultiPageInternalLink = (
	linkName: string,
	topic: TopicType,
	guideTitle: IAllMultipageGuideListType,
	step: number,
): JSX.Element => {
	return (
		<BasicLink
			href={getMultiStepGuideHref(topic, guideTitle, step)}
			name={linkName}
			className="guides-internal-link__button"
			id={`guides-internal-link__${lowercaseAndHyphen(linkName)}`}
		/>
	);
};

// @TODO: make linkId required after all guides are updated
export const GuidesExternalLink = (
	linkName: string,
	linkPath: string,
	openInNewPage = true,
	linkId?: string,
): JSX.Element => {
	return (
		<BasicLink
			type="inline-link"
			IconRight={ExternalLinkIcon}
			openInNewPage={openInNewPage}
			href={linkPath}
			name={linkName}
			className="guides-external-link__button"
			id={`guides-external-link__${linkId ? linkId : lowercaseAndHyphen(linkName)}`}
		/>
	);
};

// @TODO: make linkId required after all guides are updated
export const GuidesInternalLink = (linkName: string, linkPath: string, linkId?: string): JSX.Element => {
	return (
		<BasicLink
			type="inline-link"
			href={linkPath}
			name={linkName}
			className="guides-internal-link__button"
			id={`guides-internal-link__${linkId ? linkId : lowercaseAndHyphen(linkName)}`}
		/>
	);
};

export const GuidesTelephoneNumber = (telephoneNumber: string): JSX.Element => {
	return <span className="nowrap no-padding">{telephoneNumber}</span>;
};

export const GuidesEmail = (email: string): JSX.Element => {
	return <BasicLink id={`guides-email__${email}`} name={email} href={`mailto:${email}`} disablePrefetch={true} />;
};

export const GuidesBulletList = (listStyle: "ol" | "ul", listItems: any[]): JSX.Element => {
	const Element: any = listStyle;
	return (
		<>
			<Element>
				{listItems.map((item: JSX.Element | string, index: number): JSX.Element => {
					return (
						<li key={index}>
							<span>{typeof item === "string" ? parse(item) : item}</span>
						</li>
					);
				})}
			</Element>
		</>
	);
};

export const GuidesImages = (listItems: IGuideImage[]): JSX.Element => {
	return (
		<div className="guides-image-container">
			{listItems.map((item: IGuideImage, index: number): JSX.Element => {
				return <img src={item.src} alt={item.alt} style={item.style || undefined} key={index} />;
			})}
		</div>
	);
};
