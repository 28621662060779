import Link from "next/link";
import Pill, { PillType } from "../Pill";
import "./Card.scss";

interface ICardWithImageProps {
	id: string;
	header: string;
	description: string;
	image: string;
	imageAltText?: string;
	href: string;
	buttonBelowImage?: JSX.Element;
	pillType?: PillType;
	pillTag?: boolean;
	PillText?: string;
}

const MaintenanceBanner = (props: ICardWithImageProps): JSX.Element => {
	return (
		<Link href={props.href}>
			<div id={props.id} className="card--with-image-and-pill">
				<img src={props.image} alt={props.imageAltText ?? ""} />
				<div className="card--with-image-and-pill__header">
					<Pill
						text={props.PillText || ""}
						type={props.pillType || PillType.neutral}
						isTag={props.pillTag || false}
					/>
					<h4 className="mt8 semi-bold">{props.header}</h4>
					<p className="mt8 mb0 card--with-image-and-pill__description">{props.description}</p>
				</div>
			</div>
		</Link>
	);
};

export default MaintenanceBanner;
