import { ChevronRightIcon } from "@lifesg/react-icons/chevron-right";
import Link from "next/link";
import { ReactNode } from "react";
import "./Card.scss";

export interface ICardProps {
	onClick?: () => void;
	id?: string;
	href?: string;
	urlAs?: string;
	showArrow?: boolean;
	className?: string;
	isStatic?: boolean;
	openInNewPage?: boolean;
	width?: 75 | 100;
	disabled?: boolean;
	isFloating?: boolean;
	children: ReactNode;
}

const Card = (props: ICardProps): JSX.Element => {
	let Element: any = "div";

	if (props.href && !props.disabled) {
		Element = "a";
	}

	let className = "card";
	if (props.showArrow) {
		className += " card--with-arrow";
	}

	if (props.isStatic) {
		className += " card--static";
	}

	if (props.disabled) {
		className += " card--disabled";
	}

	if (props.width) {
		className += ` card--${props.width}`;
	}

	if (props.isFloating) {
		className += " card--floating";
	}

	if (props.className) {
		className += ` ${props.className}`;
	}

	const mainElement = (
		<Element
			className={className}
			onClick={props.onClick}
			id={props.id}
			href={props.disabled === true ? false : props.href}
			target={props.openInNewPage ? "_blank" : ""}
		>
			{props.children}
			{props.showArrow && (
				<div className="card__arrow">
					<ChevronRightIcon className="ds-icon--base" />
				</div>
			)}
		</Element>
	);

	if (props.href && !props.disabled) {
		return (
			<Link passHref={true} href={props.href} as={props.urlAs}>
				{mainElement}
			</Link>
		);
	}

	return mainElement;
};

export default Card;
