import { ChevronRightIcon } from "@lifesg/react-icons/chevron-right";
import Link from "next/link";
import "./styles.scss";

interface IPlainCardProps {
	id: string;
	header: string;
	description: string;
	href: string;
}

const PlainCard = (props: IPlainCardProps): JSX.Element => {
	const Header = (): JSX.Element => (
		<div className="secure-sharing__card__header">
			<div className="secure-sharing__card__plain-name">
				<h4 className="semi-bold">{props.header}</h4>
			</div>
			<div className="secure-sharing__card__arrow">
				<ChevronRightIcon />
			</div>
		</div>
	);

	const Description = (): JSX.Element => <div className="secure-sharing__card__bottom">{props.description}</div>;

	return (
		<div className="secure-sharing__card">
			<Link href={props.href}>
				<div id={props.id} className="secure-sharing__card__top">
					<Header />
				</div>
			</Link>
			<Description />
		</div>
	);
};

export default PlainCard;
