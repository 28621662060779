import BasicLink from "app/components/basic/Link";
import Divider from "app/components/widgets/Divider";
import { ILinkItem } from "app/utils/interfaces";
import { lowercaseAndHyphen } from "app/utils/strings";
import * as React from "react";
import "./RelatedLinks.scss";

export interface IProps {
	headerText: string;
	linkItems: ILinkItem[];
	openInNewPage?: boolean;
	className?: string;
}

const RelatedLinks = (props: IProps): JSX.Element => {
	if (props.linkItems.length === 0) {
		return <></>;
	}

	let className = "related-links__link-list__link";

	if (props.className) {
		className += ` ${props.className}`;
	}

	return (
		<div className="related-links">
			<div id="related-links__header" className="font-type-two-xs">
				{props.headerText}
			</div>
			<div className="related-links__link-list">
				{props.linkItems.map((linkItem: ILinkItem, index: number) => {
					return (
						<React.Fragment key={index}>
							<BasicLink
								type="no-underline-link"
								className={className}
								name={linkItem.name}
								href={linkItem.path}
								id={`related-links__${lowercaseAndHyphen(linkItem.name)}`}
								openInNewPage={props.openInNewPage}
								IconRight={linkItem.iconRight}
							/>
							<Divider marginBottom={16} marginTop={17} />
						</React.Fragment>
					);
				})}
			</div>
		</div>
	);
};

export default RelatedLinks;
