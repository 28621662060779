import Card from "app/components/basic/Card";
import translations from "app/assets/files/key-value-translations/homepage.json";
import React from "react";
import { IProps } from "app/pages";
import "./NoEventCard.scss";

interface Props extends IProps {
	isApiError: boolean;
}
const NoEventCard = ({ isApiError, language }: Props): JSX.Element => {
	const text = {
		heading: "There is no upcoming workshop at the moment.",
		subHeading: "Please check again later.",
		subHeadingMobile: "There is no upcoming workshop at the moment. Please check again later.",
	};

	if (isApiError) {
		text.heading = "Service is currently unavailable.";
		text.subHeading = "Please refresh and try again later.";
		text.subHeadingMobile = "Service is currently unavailable, please refresh and try again later.";
	}

	return (
		<Card isStatic data-testid="no-event-card" className="no-event-card">
			<img
				className="no-event-card__image-workshops"
				src="/images/upcoming-workshops.png"
				alt={translations[language].homepageUpcomingWorkshopsImage.data}
				height={128}
				width={188}
			/>
			<div className="no-event-card__subheading--desktop">
				<p className="bold">{text.heading}</p>
				<p className="color-grey-80">{text.subHeading}</p>
			</div>
			<div className="no-event-card__subheading--mobile">
				<p className="color-grey-80">{text.subHeadingMobile}</p>
			</div>
		</Card>
	);
};

export default NoEventCard;
